<i18n src="./locales.json"></i18n>
<template>
  <v-text-field
    :id="idProp"
    v-model="formInput.$model"
    :label="label || $t && $t('L_address') || 'Address'"
    :disabled="disabled"
    :maxlength="maxlen"
    :color="color"
    :autocomplete="disableAutocomplete ? 'off' : null"
    :append-icon="icon"
    :error-messages="errorMessages"
    @input="onInput"
    @blur="onBlur"
    @keydown="onKeyDown"
    :aria-describedby="describers"
  >
    <FieldIcon
      slot="append"
      :form-field="formInput"
    />
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'AddressInput',
  components: { FieldIcon },
  extends: BaseTextField,
  props: {
    idProp: {
      type: String,
      required: false,
      default: 'address',
    },
    maxlen: {
      type: Number,
      required: false,
      default: 50,
    },
  },
  data: () => ({
    modelName: 'address',
    address: '',
  }),
  methods: {
    onKeyDown(e) {
      // hyphen,                   a-z includes A-Z,                       .             space          apostrophe              hashtag
      if (e.key === '-' || (e.keyCode >= 65 && e.keyCode <= 90) || (e.key === '.' || e.key === ' ' || e.key === "'" || e.key === '#') ||
        // Numbers 0 through 9
        (e.key === '0' || e.key === '1' || e.key === '2' || e.key === '3' || e.key === '4' || e.key === '5' || e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9') ||
        // backspace, tab, End, Home, arrow Left, Arrow Up, Arrow Right, Arrow Down, Select, Print, Execute, F13, insert, delete,
        (e.keyCode === 8) || (e.keyCode === 9) || (e.keyCode >= 35 && e.keyCode <= 46)
      ) {
        return;
      } e.preventDefault();
    },
    onBlur() {
      this.formInput.$model = this.formInput.$model.trim();
      const indexFound = this.findFirstAlphaOccurrence(this.formInput.$model);
      if (indexFound > -1) {
        this.formInput.$model = this.formInput.$model.slice(0, indexFound) + this.formInput.$model.charAt(indexFound).toUpperCase() + this.formInput.$model.slice(indexFound + 1);
      }
    },
    findFirstAlphaOccurrence(str) {
      const match = str.search(/[a-zA-Z]/);
      return match === -1 ? -1 : match;
    },
  },
};
</script>
