<i18n src="../locales.json"></i18n>
<template>
  <v-container>
    <v-layout column>
      <v-layout row wrap>
        <v-flex xs12 font-size--16 mb-4>
          {{ $t('L_you_have_two_options') }}
        </v-flex>
        <v-flex xs12 font-size--16 mb-4>
          {{ $t('L_if_you_do_not_want_to_pay_all_past_due') }}
        </v-flex>
        <v-flex xs12 md6 mb-4>
          <div class="mb-4 font-weight-bold font-size--16">
            {{ $t('L_reinstate_with_original_issue_date') }}
          </div>
          <v-card @click="selectedAmount = 0" :class="selectedAmount === 0 ? 'cardSelected' : 'cardNotSelected'" class="clickable">
            <v-layout row wrap>
              <v-flex xs6>
                <div class="cardAmount" v-if="get_paymentAsOfOrgIssueDate !== '$0.00'">
                  {{ get_paymentAsOfOrgIssueDate }}
                </div>
              </v-flex>
              <v-flex xs6 class="vertCenterCircleHoriRight">
                <div v-if="selectedAmount === 0">
                  <v-icon color="green">
                    check_circle
                  </v-icon>
                </div>
              </v-flex>
              <div class="cardFooter" zv-if="ifNotInsufficientFund">
                {{ $t('L_this_option_requires_you_to_pay_the_premium') }}
              </div>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 md6>
          <div class="mb-4 font-weight-bold font-size--16">
            {{ $t('L_reinstate_with_reissue_date') }}
          </div>
          <v-card @click="selectedAmount = 1" :class="selectedAmount === 1 ? 'cardSelected' : 'cardNotSelected'" class="clickable">
            <v-layout row wrap>
              <v-flex xs6>
                <div class="cardAmount" v-if="get_paymentAsOfReIssueDate !== '$0.00'">
                  {{ get_paymentAsOfReIssueDate }}
                </div>
              </v-flex>
              <v-flex xs6 class="vertCenterCircleHoriRight">
                <div v-if="selectedAmount === 1">
                  <v-icon color="green">
                    check_circle
                  </v-icon>
                </div>
              </v-flex>
              <div class="cardFooter">
                {{ $t('L_this_option_requires_a_redate_of_the_policy') }}
              </div>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 font-size--16 mt-4 mb-4 v-if="get_paymentAsOfOrgIssueDate !== '$0.00' && get_paymentAsOfReIssueDate !== '$0.00'">
          <span v-html="$t('L_important_note_premium_amount_is_subject_to_change')" />
        </v-flex>
      </v-layout>
      <v-flex xs12 mt-4>
        <v-layout row wrap class="zoffset-xs3 text-xs-right pl-2 pr-2" :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
          <v-flex xs8 sm3 mb-3 :class="$vuetify.breakpoint.xsOnly?'':'mr-3'">
            <v-btn :disabled="disableActions" block color="secondary" class="mr-2" @click="cancelAction">
              {{ $t('L_cancel') }}
            </v-btn>
          </v-flex>
          <v-flex xs8 sm3 mb-3>
            <v-btn :disabled="disableActions || selectedAmount === -1" target="_blank" block color="primary" @click="handleContinueAction">
              {{ $t('L_continue') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex';
import { currency } from '@/shared/util/formatters';

export default {
  name: 'ReinstatementProcessOptions',
  props: {
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedAmount: -1,
  }),
  computed: {
    ...mapGetters('policy', {
      currentPolicy: 'currentPolicy',
    }),
    get_paymentAsOfOrgIssueDate() {
      return currency(+this.currentPolicy?._reinstatement?._paymentAsOfOrgIssueDate, 2);
    },
    get_paymentAsOfReIssueDate() {
      return currency(+this.currentPolicy?._reinstatement?._paymentAsOfReIssueDate, 2);
    },
  },
  methods: {
    handleContinueAction() {
      this.$emit('userOption', this.selectedAmount);
      this.continueAction();
    },
    continueAction() {
      this.$emit('continueClicked');
    },
    cancelAction() {
      this.$emit('cancelClicked');
    },
  },
};
</script>

<style lang="stylus" scoped>
.bg-light-grey {
  background-color: #f5f5f5;
}
.cardNotSelected {
  max-width: 295px;
  border: 2px solid #cccccc;
  background-color: #ffffff;
  zheight: 85px;
  padding: 15px 15px 15px 15px;
}

.cardSelected {
  max-width: 295px;
  border: 2px solid #6cd07b;
  background-color: #d8f8dd;
  zheight: 85px;
  padding: 15px 15px 15px 15px;
}

.cardTitle {
  padding-top: 16px;
  padding-left: 16px;
  font-size: 12px;
  color: #333333;
}
.cardAmount {
  padding-top: 0px;
  zpadding-left: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.cardFooter {
  padding-top:8px;
  font-size: 12px;
  color: #333333;
}
.vertCenterCircleHoriRight {
  margin: auto;
  text-align: right;
  padding-right: 10px;
}
.firstTextContent {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.secondTextContent {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.thirdTextContent {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.maxwidthButton {
  max-width: 300px;
}
.moveRight {
  text-align: right;
}
.clickable {
  cursor: pointer;
}
</style>

