<template>
  <v-text-field
    :id="idProp"
    v-model="formInput.$model"
    :disabled="disabled"
    :maxlength="maxlen"
    :label="label || $t && $t('L_city') || 'City'"
    :autocomplete="disableAutocomplete ? 'off' : null"
    :color="color"
    :append-icon="icon"
    :error-messages="errorMessages"
    @input="onInput"
    @blur="onBlur"
    @keydown="onKeyDown"
    :aria-describedby="describers"
  >
    <FieldIcon
      slot="append"
      :form-field="formInput"
    />
  </v-text-field>
</template>

<script>
import FieldIcon from '@/components/FieldIcon/FieldIcon.vue';
import BaseTextField from '../BaseTextField.vue';

export default {
  name: 'CityInput',
  components: { FieldIcon },
  extends: BaseTextField,
  props: {
    idProp: {
      type: String,
      required: false,
      default: 'city',
    },
    maxlen: {
      type: Number,
      required: false,
      default: 25,
    },
  },
  data: () => ({
    modelName: 'city',
    city: '',
  }),
  methods: {
    onKeyDown(e) {
      // hyphen,                   a-z includes A-Z,                       .             space        and apostrophe
      if (e.key === '-' || (e.keyCode >= 65 && e.keyCode <= 90) || (e.key === '.' || e.key === ' ' || e.key === "'") ||
        // backspace, tab, End, Home, arrow Left, Arrow Up, Arrow Right, Arrow Down, Select, Print, Execute, F13, insert, delete,
        // number on top of keyboard
        (e.keyCode === 8) || (e.keyCode === 9) || (e.keyCode >= 35 && e.keyCode <= 46)
      ) {
        return;
      } e.preventDefault();
    },
    onBlur() {
      this.formInput.$model = this.formInput.$model.trim();
      this.formInput.$model = this.formInput.$model.charAt(0).toUpperCase() + this.formInput.$model.slice(1);
    },
  },
};
</script>
