<i18n src="../locales.json"></i18n>
<template>
  <div class="ml-4 mr-4">
    <div :class="$vuetify.breakpoint.xsOnly?'ml-4':''">
      <v-layout column class="ml-4 mt-4 containerWidth">
        <v-layout row wrap>
          <v-flex xs12 font-weight-bold text-xs-center mt-4 mb-4 class="reinstateTitle">
            {{ $t('L_request_reinstatement_application') }}
          </v-flex>
          <v-flex xs12 font-size--14 font-weight-regular text-xs-left mb-4>
            {{ $t('L_to_reinstate_your_coverage_we_will_mail_you_a_reinstatement_application') }}
          </v-flex>
          <v-flex xs12 mb-4 font-weight-bold>
            {{ $t('L_Follow_the_steps_below_to_complete_the_reinstatement_application') }}
          </v-flex>
          <v-flex xs12 font-size--14 font-weight-bold text-xs-left mb-4 class="blueText">
            <ul>
              <li>{{ $t('L_Provide_all_required_information') }}</li>
              <li>{{ $t('L_Each_adult_insured_will_need_to_answer_the_medical_history_questions') }}</li>
              <li>{{ $t('L_indicate_premium_payment_option_and_provide_required_payment_details') }}</li>
              <li>{{ $t('L_Each_adult_insured_should_sign_the_application') }}</li>
              <li>{{ $t('L_Mail_the_completed_application_to_the_mailing_address_on_the_application') }}</li>
            </ul>
          </v-flex>
          <v-flex xs12 font-size--14 font-weight-regular text-xs-left mb-4>
            {{ $t('L_The_reinstatement_application_will_be_mailed_to_the_address_below') }}
          </v-flex>
          <v-flex xs12 font-size--16 font-weight-bold text-xs-left mb-4 :class="$vuetify.breakpoint.xsOnly?'ml-4':''">
            {{ $t('L_Your_Reinstatement_application_forms_will_be_mailed_to') }}
          </v-flex>
          <v-flex xs12 font-size--14 font-weight-bold text-xs-left mb-2 :class="$vuetify.breakpoint.xsOnly?'ml-4':''">
            {{ $t('L_Your_Address') }}
            <span class="blueReinstatementText ml-4" @click="update=true;">
              <v-icon color="#0C6DCA">edit</v-icon>
              {{ $t('L_Update') }}
            </span>
          </v-flex>
          <v-flex xs12 v-if="update === false" class="font-size--16 font-weight-regular" :class="$vuetify.breakpoint.xsOnly?'ml-4':''">
            <div>{{ streetaddress1 }}</div>
            <div>{{ streetaddress2 }}</div>
            <div>{{ city }}, {{ stateData }}</div>
            <div>{{ zip }}</div>
          </v-flex>
          <v-flex xs12 sm6 md5 v-else>
            <AddressInput
              id-prop="update_contact_details_address_one"
              v-model="streetaddress1"
              :form-input="$v.streetaddress1"
              :maxlen="50"
              :label="$t('L_Street_Address_-_Line_1')"
              :auto-capitalize="false"
              :validation-messages="{
                required: $t('L_address_line_1_required'),
              }"
            />
            <AddressInput
              id-prop="update_contact_details_address_two"
              v-model="streetaddress2"
              :form-input="$v.streetaddress2"
              :label="$t('L_Street_Address_-_Line_2_optional')"
              :auto-capitalize="false"
              :maxlen="50"
            />
            <CityInput
              id-prop="update_contact_details_city"
              v-model="city"
              :form-input="$v.city"
              :label="$t('L_City')"
              :maxlen="25"
            />
            <StateInput
              v-if="!currentPolicy.isCanadaPolicy"
              id-prop="update_contact_details_state"
              v-model="stateData"
              :form-input="$v.usStateData"
              :label="$t('L_State')"
            />
            <TerritoryInput
              v-if="currentPolicy.isCanadaPolicy"
              id-prop="update_contact_details_province"
              v-model="stateData"
              :form-input="$v.caTerritoryData"
              :label="$t('L_policy_province')"
            />

            <ZipCodeInput
              v-if="!currentPolicy.isCanadaPolicy"
              id-prop="update_contact_details_zip_code"
              v-model="zip"
              :form-input="$v.zip"
              :label="$t('L_Zip_Code')"
            />
            <PostalCodeInput
              v-if="currentPolicy.isCanadaPolicy"
              id-prop="update_contact_details_zip_code"
              v-model="zip"
              :territory="stateData"
              :form-input="$v.zip"
              :label="$t('L_policy_postal_code')"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="font-size--16 mt-4">
              <i18n path="L_if_you_have_questions_please_contact_your_representative_or_client_service" tag="p" class="font-weight-regular">
                <span>{{ customerServicePhoneNum }}</span>
              </i18n>
            </div>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 mt-4 mr-4>
          <v-layout row wrap class="zoffset-xs3 text-xs-right pl-2 pr-2" :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
            <v-flex xs12 sm3 md3 mb-3 :class="$vuetify.breakpoint.xsOnly?'':'mr-3'">
              <v-btn :disabled="disableActions" block color="secondary" class="mr-2" @click="cancelAction">
                {{ $t('L_cancel') }}
              </v-btn>
            </v-flex>
            <v-flex xs12 sm6 md4 mb-3>
              <v-btn :disabled="disableActions || disableBtnReinstatementNextStepPage" target="_blank" block color="primary"
                     @click="continueAction"
                     :class="$vuetify.breakpoint.xsOnly ? 'font-size--12' : 'font-size--14'"
              >
                {{ $t('L_Request_Application') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
// import { maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import {
  AddressInput,
  AddressValidation,
  CityInput,
  CityValidation,
  StateInput,
  StateValidation,
  TerritoryInput,
  TerritoryValidation,
  ValidForTerritory,
  ZipCodeInput,
  ZipCodeValidation,
  PostalCodeInput,
  PostalCodeValidation,
} from '@/components/Inputs';

export const addressLine1Validation = () => (value) => {
  const regex = /^[a-zA-Z0-9\-.# ']+ ?[a-zA-Z0-9\-.# ']+$/;
  const match = value && value.match(regex);
  if (value === '') {
    return true;
  }
  // if value doesn't match the regex match will be null so !!(match) will be false
  if (!!match === false) {
    return false;
  }
  const addressAlphaOnlyStr = value.replace(/[^a-zA-Z]/g, '');
  if (addressAlphaOnlyStr.length < 1) {
    return false;
  }
  return true;
};
export const addressLine2Validation = () => (value) => {
  const regex = /^[a-zA-Z0-9\-.# ']+ ?[a-zA-Z0-9\-.# ']+$/;
  const match = value && value.match(regex);
  if (value === '') {
    return true;
  }
  // if value doesn't match the regex match will be null so !!(match) will be false
  if (!!match === false) {
    return false;
  }
  return true;
};
export const cityValidation = () => (value) => {
  const regex = /^[a-zA-Z\-.']+ ?[a-zA-Z\-. ']+$/;
  const match = value && value.match(regex);
  if (value === '') {
    return true;
  }
  // if value doesn't match the regex match will be null so !!(match) will be false
  if (!!match === false) {
    return false;
  }
  return true;
};


export default {
  name: 'ReinstatementNYStepThree',
  components: {
    AddressInput,
    AddressValidation,
    CityInput,
    CityValidation,
    StateInput,
    StateValidation,
    TerritoryInput,
    TerritoryValidation,
    ValidForTerritory,
    ZipCodeInput,
    ZipCodeValidation,
    PostalCodeInput,
    PostalCodeValidation,
  },
  props: {
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    update: false,
    streetaddress1: '',
    streetaddress2: '',
    city: '',
    stateData: '',
    zip: '',
  }),
  computed: {
    ...mapGetters('policy', ['currentPolicy']),
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters({ getLocale: 'getLocale' }),
    customerServicePhoneNum() {
      if (this.getLocale === 'en_US') {
        return '(800) 257-4725';
      } else if (this.getLocale === 'en_CA') {
        return '(800) 387-7876';
      } else if (this.getLocale === 'fr_CA') {
        return '(800) 265-4804';
      }
      return '(800) 257-4725';
    },
    disableBtnReinstatementNextStepPage() {
      if (!this.update) {
        return false;
      } else if (this.currentPolicy?.isCanadaPolicy === undefined || this.currentPolicy?.isCanadaPolicy === false) { // US
        // US
        // This is to remove all non alpha characters to check length meets min of 2
        const cityAlphaOnlyStr = this.city.replace(/[^a-zA-Z]/g, '');
        if (
          this.$v.streetaddress1.$anyError ||
            this.$v.streetaddress2.$anyError ||
            this.$v.city.$anyError ||
            this.$v.usStateData.$anyError ||
            this.streetaddress1.trim().length === 0 ||
            this.$v.zip.$anyError ||
            cityAlphaOnlyStr.length < 2 ||
            this.stateData.length === 0 ||
            this.zip.length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.currentPolicy?.isCanadaPolicy) {
        // Canada
        // This is to remove all non alpha characters to check length meets min of 2
        const cityAlphaOnlyStr = this.city.replace(/[^a-zA-Z]/g, '');
        if (
          this.$v.streetaddress1.$anyError ||
            this.$v.streetaddress2.$anyError ||
            this.$v.city.$anyError ||
            this.$v.caTerritoryData.$anyError ||
            this.streetaddress1.trim().length === 0 ||
            this.$v.zip.$anyError ||
            cityAlphaOnlyStr.length < 2 ||
            this.stateData.length === 0 ||
            this.zip.length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    stateData(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        if (!this.currentPolicy?.isCanadaPolicy) {
          this.$v.usStateData.$model = this.stateData;
        } else {
          this.$v.caTerritoryData.$model = this.stateData;
        }
      }
    },
  },
  methods: {
    continueAction() {
      const userObj = {
        streetaddress1: this.streetaddress1,
        streetaddress2: this.streetaddress2,
        city: this.city,
        state: this.stateData,
        zip: this.zip,
      };
      this.$emit('continue-to-the-next-reinstatement-step', userObj);
    },
    cancelAction() {
      this.$emit('cancelClicked');
    },
  },
  mounted() {
    this.streetaddress1 = this.currentPolicy?.data?.ownerAddress.address1 || '';
    this.streetaddress2 = this.currentPolicy?.data?.ownerAddress.address2 || '';
    this.city = this.currentPolicy?.data?.ownerAddress.city || '';
    this.stateData = this.currentPolicy?.data?.ownerAddress.state || '';
    this.zip = this.currentPolicy?.data?.ownerAddress.zip || '';
  },
  validations() {
    return {
      streetaddress1: {
        ...AddressValidation,
        addressLine1Validation: addressLine1Validation(),
      },
      streetaddress2: {
        ...AddressValidation,
        // maxlength: maxLength(35),
        addressLine2Validation: addressLine2Validation(),
      },
      city: {
        CityValidation,
        cityValidation: cityValidation(),
        // cityMax: this.cityData.length <= 25,
      },
      caTerritoryData: TerritoryValidation,
      usStateData: StateValidation,
      zip: this?.currentPolicy?.isCanadaPolicy
        ? {
          ...PostalCodeValidation,
          validForTerritory: ValidForTerritory(this.stateData, this.zip),
        }
        : {
          ...ZipCodeValidation,
        },
    };
  },
};
</script>

<style lang="stylus" scoped>
.reinstateTitle {
  font-size: 22px;
}
.blueReinstatementText {
  color: #0C6DCA;
  cursor: pointer;
}
.containerWidth {
  max-width: 649px;
  width: 100%;
  margin: auto !important;
}
.blueText {
  color: #063562;
}
</style>
