import moment from 'moment';
import { Agent } from './Agent';
import { Beneficiary } from './Beneficiary';
import { Owner } from './Owner';
import { Address } from './Address';
import { Phase } from './Phase';
import { Person } from './Person';
import { Payment } from './Payment';
import { Reinstatement } from './Reinstatement';
import Config from '../../../shared/util/config';

export class Policy {
  _contractId;

  _payments = [];

  _paymentInfo = {};

  _beneficiaries = [];

  _phases = [];

  _annuity;

  _childRider;

  _owner = {};

  _primaryInsured = {};

  data = {};

  _servicingAgent;

  _servicingRvp;

  _showAddChildLink;

  _showPayNowLink;

  _payNowLinkDesc;

  _kubraPaymentStatus;

  _agentId;

  _documents;

  _reinstatement;

  _setBeneficiaries(beneficiaries) {
    this._beneficiaries = (beneficiaries || []).map(beneficiary => new Beneficiary(beneficiary));
  }

  _setReinstatement(reinstatement) {
    this._reinstatement = new Reinstatement({
      paymentAsOfReIssueDate: reinstatement.paymentAsOfReIssueDate,
      paymentAsOfOrgIssueDate: reinstatement.paymentAsOfOrgIssueDate,
      termDate: reinstatement.termDate,
      pacPremium: reinstatement.pacPremium,
      eligible: reinstatement.eligible,
      redirectUrl: reinstatement.redirectUrl,
    });
  }

  _setPayments(payments) {
    this._payments = (payments || []).map(payment => ({
      date: Policy.parseDate(payment.PaymentDate),
      amount: Phase.parseNum(payment.PaymentAmount),
    }));
  }

  _setPaymentInfo(paymentInfo) {
    this._paymentInfo = new Payment(paymentInfo);
  }

  _setPhases(phases) {
    this._phases = (phases || []).map(phase => new Phase(phase));
    const [annuity] = this._phases.filter(phase => phase.phaseIsAnnuity === 'true');
    this._annuity = annuity;
    const [childRider] = this._phases.filter(({ phPlanzPerson }) => phPlanzPerson === '20');
    this._childRider = childRider;
  }


  _setOwner(policyData) {
    this._owner = new Owner({
      firstName: policyData.ownerFirstName,
      lastName: policyData.ownerLastName,
      dob: Policy.parseDate(policyData.ownerDOB, 'MM/DD/YYYY'),
      ssn: policyData.ownerSSN,
      address: new Address({
        phone: policyData.phoneNumber,
        ...policyData?.ownerAddress,
      }),
      email: policyData.clientEmailaddress,
    });
  }


  constructor({
    contractId,
    policyStatusDesc,
    servicingAgent,
    servicingRVP,
    policyData,
    totalAnnuityCoverage,
    totalChildCoverage,
    totalSpouseCoverage,
    totalPrimaryCoverage,
    totalActivePrimaryCoverage,
    totalActiveSpouseCoverage,
    access,
    allowAllOtherUpdates,
    allowContactInfoUpdate,
    allowDocumentsPrint,
    allowOneTimePayment,
    allowPaymentInfoUpdates,
    showAddChildLink,
    showOneTimePayment,
    payNowLinkDesc,
    kubraPaymentStatus,
    agentId,
    documents,
    insuredFirstName,
    insuredLastName,
    reinstatement,
  }) {
    this._contractId = contractId;
    this.data = {
      totalActivePrimaryCoverage,
      totalActiveSpouseCoverage,
      totalAnnuityCoverage,
      totalChildCoverage,
      totalPrimaryCoverage,
      totalSpouseCoverage,
      access,
      allowAllOtherUpdates,
      allowContactInfoUpdate,
      allowDocumentsPrint,
      allowOneTimePayment,
      allowPaymentInfoUpdates,
      ...policyData,
    };
    if (policyStatusDesc) {
      this.policyStatusDesc = policyStatusDesc;
    }
    if (servicingAgent) {
      servicingAgent.rvpAndUp = policyData?.servicingAgtID === policyData?.servicingRVPID;
    }
    this._servicingAgent = servicingAgent
      ? new Agent(servicingAgent)
      : null;
    this._servicingRvp = servicingRVP
      ? new Agent(servicingRVP)
      : null;
    this._showAddChildLink = showAddChildLink;
    this._showPayNowLink = showOneTimePayment;
    this._payNowLinkDesc = payNowLinkDesc;
    this._kubraPaymentStatus = kubraPaymentStatus;
    this._agentId = agentId;
    this._documents = documents;

    if (policyData) {
      this._setPayments(policyData.payments);
      this._setBeneficiaries(policyData.beneficiaries);
      this._setPhases(policyData.phases);
      this._setOwner(policyData);
      this._setPaymentInfo(policyData);
    }
    this._primaryInsured = new Person({
      PersonFirstName: insuredFirstName,
      PersonLastName: insuredLastName,
    });
    if (reinstatement) {
      this._setReinstatement(reinstatement);
    }
  }

  get agentId() {
    return this._agentId || '';
  }

  get contractId() {
    return this._contractId || '';
  }

  get status() {
    return this.data?.policyStatus;
  }

  get statusText() {
    return this.data?.policyStatusLong || '';
  }

  get active() {
    return (this.statusText === 'Active');
  }

  get pending() {
    return (this.statusText === 'Pending');
  }

  get terminated() {
    return (this.statusText === 'Terminated');
  }

  get recordId() {
    return this.data?.policyRecordId || '';
  }

  get recordStatus() {
    return this.data?.policyRecordStatus || '';
  }

  get restrictCode() {
    return this.data?.restrictCode || '';
  }

  get restricted() {
    return this?.data?.access?.toUpperCase() === 'NONE';
  }

  get state() {
    return this.data?.state || '';
  }

  get servicingAgent() {
    return this._servicingAgent;
  }

  get servicingRvp() {
    return this._servicingRvp;
  }

  get policyNumber() {
    return this.data?.policyNum || '';
  }

  get primaryInsuredIsOwner() {
    return this.data?.primaryInsuredIsOwner === true || this.data?.primaryInsuredIsOwner === 'true';
  }

  get clientEmail() {
    return this.data?.clientEmailAddress || undefined;
  }

  get premiumLocation() {
    return this.data?.PremiumLocation || '';
  }

  get writingAgent() {
    return (!!this.data?.writingAgent && !!this.data?.writingAgentName && !!this.data?.writingAgentStatus) ? {
      id: this.data?.writingAgent || '',
      name: this.data?.writingAgentName || '',
      status: this.data?.writingAgentStatus || '',
    } : undefined;
  }

  get writingAgent2() {
    return (!!this.data?.writingAgent2 && !!this.data?.writingAgentName2 && !!this.data?.writingAgentStatus2) ? {
      id: this.data.writingAgent2 || '',
      name: this.data.writingAgentName2 || '',
      status: this.data.writingAgentStatus2 || '',
    } : undefined;
  }

  get insuredName() {
    return `${this._primaryInsured.firstName} ${this._primaryInsured.lastName}`;
  }

  get billingForm() {
    return this.data?.billingForm || '';
  }

  get billingMode() {
    return this.data?.billingMode || '';
  }

  get wipRecordExists() {
    return this.data?.wipRecordExists === true || this.data?.wipRecordExists === 'true';
  }

  get payments() {
    return this._payments;
  }

  get paymentInfo() {
    return this._paymentInfo;
  }

  set paymentInfo(paymentInfo) {
    this._setPaymentInfo(paymentInfo);
  }

  get bank() {
    return this._bank;
  }

  get owner() {
    return this._owner;
  }

  get beneficiaries() {
    return this._beneficiaries;
  }

  get issueDate() {
    return Policy.parseDate(this.data?.issueDate, 'MM/DD/YYYY');
  }

  get billToDate() {
    return Policy.parseDate(this.data?.billToDate, 'MM/DD/YYYY');
  }

  get phases() {
    return this._phases;
  }

  get totalCoverage() {
    return +this.primaryCoverage + this.spouseCoverage + this.childCoverage + this.annuityCoverage;
  }

  get primaryCoverage() {
    return +this.data?.totalPrimaryCoverage || 0;
  }

  get activePrimaryCoverage() {
    return +this.data?.totalActivePrimaryCoverage || 0;
  }

  get spouseCoverage() {
    return +this.data?.totalSpouseCoverage || 0;
  }

  get activeSpouseCoverage() {
    return +this.data?.totalActiveSpouseCoverage || 0;
  }

  get childCoverage() {
    return +this.data?.totalChildCoverage || 0;
  }

  get annuityCoverage() {
    return +this.data?.totalAnnuityCoverage || 0;
  }

  get modePremium() {
    return Phase.parseNum(this.data?.modePremium);
  }

  get totalPremium() {
    const prem = this.phases.map(phase => +phase.PhPremium).reduce((current, aggregator) => aggregator + current, 0);
    return Number.isNaN(prem) ? this.modePremium : prem;
  }

  get ratings() {
    return this.data?.Ratings || [];
  }

  get bankName() {
    return this.data?.BankName || '';
  }

  get annuityAmount() {
    return +this.data?.totalAnnuityCoverage || 0;
  }

  get hasAnnuity() {
    return this.data?.annuityModePrem > 0;
  }

  get annuity() {
    return this._annuity;
  }

  get paidToDate() {
    return Policy.parseDate(this.data?.paidToDate, 'MM/DD/YYYY');
  }

  get nextDraftDate() {
    return Policy.parseDate(this.data?.nextSchedDraftDate, 'MM/DD/YYYY');
  }

  get nextPaymentDueDate() {
    return Policy.parseDate(this.data?.nextPaymentDueDate, 'MM/DD/YYYY');
  }

  get primaryInsured() {
    return this._primaryInsured;
  }

  get canPrintDocuments() {
    return this.data?.allowDocumentsPrint === true || this.data?.allowDocumentsPrint === 'Y';
  }

  get canUpdateAll() {
    return this.data?.allowAllOtherUpdates === true || this.data?.allowAllOtherUpdates === 'Y';
  }

  get canUpdateContactInfo() {
    return this.data?.allowContactInfoUpdate === true || this.data?.allowContactInfoUpdate === 'Y';
  }

  get canUpdatePaymentInfo() {
    return this.data?.allowPaymentInfoUpdates === true || this.data?.allowPaymentInfoUpdates === 'Y';
  }

  get canMakeOneTimePayment() {
    return this.data?.allowOneTimePayment === true || this.data?.allowOneTimePayment === 'Y';
  }

  get showAddChildLink() {
    return this._showAddChildLink === true || this._showAddChildLink === 'true';
  }

  get showPayNowLink() {
    return this._showPayNowLink === true || this._showPayNowLink === 'true';
  }

  get kubraPaymentStatus() {
    return this._kubraPaymentStatus ? this._kubraPaymentStatus : null;
  }

  get documents() {
    return this._documents ? this._documents : null;
  }

  get payNowLinkDesc() {
    return this._payNowLinkDesc;
  }

  get rvpName() {
    return this.data?.servicingRVPName || '';
  }

  get isRvpRvp() {
    return this.data?.servicingRVPTitle === 'RVP';
  }

  get rvpTitleDesc() {
    return this.data?.servicingRVPTitleDesc || '';
  }

  get isCanadaPolicy() {
    const companyCode = this._contractId.slice(0, 2);
    return companyCode === '10' || companyCode === '05';
  }

  get isCanadaQuebecPolicy() {
    return this._owner._address._state === 'PQ';
  }

  get isCanadaQuebecBeneficiaryPolicy() {
    let isQuebec = false;
    this._phases.forEach((phase) => {
      if (phase.person === '01' && phase.issueState === 'PQ') {
        isQuebec = true;
      }
    });
    if (this._owner._address._state !== 'PQ') {
      isQuebec = false;
    }
    return isQuebec;
  }

  get allowEditBeneficiary() {
    if (!this.isCanadaPolicy) {
      return true;
    }
    const editBeneficiaryCanada = `${Config.get('life.editBeneficiaryCanada')}`;
    if (!editBeneficiaryCanada) {
      return false;
    }
    let allowed = false;
    this._phases.forEach((phase) => {
      if (phase.person === '01' && editBeneficiaryCanada.includes(phase.issueState)) {
        allowed = true;
      }
    });
    return allowed;
  }

  get dateFormat() {
    if (this.isCanadaQuebecPolicy) {
      return 'YYYY/MM/DD';
    } else if (this.isCanadaPolicy) {
      return 'DD/MM/YYYY';
    }
    return 'MM/DD/YYYY';
  }

  get isNewYorkPolicy() {
    return this._contractId.slice(0, 2) === '34';
  }

  get isUSPolicy() {
    return this._contractId.slice(0, 2) === '04';
  }

  get country() {
    return this.isCanadaPolicy ? 'ca' : 'us';
  }

  get childRider() {
    return this._childRider;
  }

  get isA1BillingForm() {
    return this?.data?.billingForm === 'A1';
  }

  get isB2BillingForm() {
    return this?.data?.billingForm === 'B2';
  }

  static parseDate(dateString, format) {
    if (typeof (dateString) !== 'string' || dateString.length === 0) {
      return null;
    }
    const date = moment(dateString, (format || 'YYYY-MM-DD')).toDate();
    return Number.isNaN(date.valueOf()) ? null : date;
  }

  get accountType() {
    return this.accountType || '';
  }
}
